<div class="container">
    <h2>Training</h2>
    <p>Training ist jeden Dienstag, ausser in den Schulferien. <a href="download/Ferienplan_2017-2019.pdf" target="_blank">Ferienplan Gais/B&uuml;hler</a></p>
    <p>Zur Zeit bestehen 5 Gruppen mit bis zu 10 Personen gemischten Alters.</p>
    <ul>
        <li>16:00-17:00</li>
        <li>17:00-18:00</li>
        <li>18:00-19:00</li>
        <li>19:00-20:00</li>
        <li>20:00-21:00</li>
    </ul>
    <p>Verschiedene Bogen-Typen stehen zum Ausprobieren zur Verf&uuml;gung</p>
    <h3>Besondere Anlagen</h3>
    <!-- Bilder von Schiessanlage -->
    <p>Unsere ferngesteuerte Schiessanlage begeistert seit einigen Jahren unsere grossen und kleinen Mitglieder.</p>
    <h3>Download</h3>
    <!-- Link zu Flyer und Anmeldeformular mit Bildern -->
</div>
