import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {HomeComponent} from './home/home.component';
import {TrainingComponent} from './training/training.component';
import {AnlasseComponent} from './anlasse/anlasse.component';
import {TechnikComponent} from './technik/technik.component';
import {GeschichteComponent} from './geschichte/geschichte.component';
import {GalerieComponent} from './galerie/galerie.component';
import {KontaktComponent} from './kontakt/kontakt.component';
import {LinksComponent} from './links/links.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'training', component: TrainingComponent },
  { path: 'anlasse', component: AnlasseComponent },
  { path: 'technik', component: TechnikComponent },
  { path: 'geschichte', component: GeschichteComponent },
  { path: 'galerie', component: GalerieComponent },
  { path: 'kontakt', component: KontaktComponent },
  { path: 'links', component: LinksComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
