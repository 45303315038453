<div class="container">
    <h2>Wilkommen beim Bogensport Gais</h2>
    <p>
        Auf diesen Seiten möchten wir mit dir unsere Freude, Begeisterung und unser Interesse am Bogensport teilen.
    </p>
    <p>
        Bogenschiessen kann jeder. Frauen, Männer und Kinder ab acht Jahren. Der Einstieg ist jederzeit, auch im fortgeschrittenen Alter möglich. Da das Bogenschiessen bei uns zwar in Gruppen, aber doch mit individueller Leistung ausgeübt wird, eignet es sich ausgezeichnet als Eltern-Kind-Aktivität. Väter oder Mütter kommen zusammen mit ihrem Kind zum Bogenschiessen und verbringen wertvolle Zeit bei einem gemeinsamen Hobby.
    </p>
    <p>
        Bogenschiessen fordert und fördert die Konzentration, die Koordination von Auge und Hand und allgemein das Gespür für den eigenen Körper.
    </p>
    <p>
        Bei uns sind alle Bogentypen vertreten. Jeder schiesst mit dem Bogen der ihm am besten entspricht. Siehe dazu auch die Informationen auf der Seite Technik.
    </p>
    <p>
        Schau doch einfach mal bei uns rein. Lege selber Hand an einen Bogen, gerne führen wir dich in die faszinierende Welt des Bogensportes ein. Bitte vereinbare dazu einen Termin mit uns, damit wir uns dir ganz widmen können.
    </p>
    <p>
        Der Bogensport Gais ist Mitglied des <a href="http://www.asta-sbv.ch/" target="blank"> Schweizer Bogenschützen-Verbandes Swiss Archery</a>
    </p>
    <p>
        Des weiteren ist der Bogensport Gais auch Mitglied der <a href="http://www.archery-faas.ch/" target="blank">Field Archery Association Switzerland</a>
    </p>
</div>
