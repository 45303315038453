<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="#" routerLink='/'>Home</a>
      <a mat-list-item href="#" routerLink='/training'>Training</a>
      <a mat-list-item href="#" routerLink='/anlasse'>Anlässe</a>
      <a mat-list-item href="#" routerLink='/technik'>Technik</a>
      <a mat-list-item href="#" routerLink='/geschichte'>Geschichte</a>
      <a mat-list-item href="#" routerLink='/galerie'>Galerie</a>
      <a mat-list-item href="#" routerLink='/kontakt'>Kontakt</a>
      <a mat-list-item href="#" routerLink='/links'>Links</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>Bogensport Gais</span>
    </mat-toolbar>
    <!-- Add Content Here -->
    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>
